import { useQuery } from '@apollo/client'
import React, { useState, useContext } from 'react'
import { Text, Box, Radio, Stack, Button, FormControl, RadioGroup, Spinner } from '@chakra-ui/react'
import { AuthContext } from 'src/utils/auth'
import { GET_NEXT_STEPS_DATA } from 'src/utils/queries'
import { milestoneDescriptions } from 'src/utils/constants'
import { WelcomeContext } from 'src/utils/providers/useWelcome'
import LogOutButton from '../LogOutButton'

const missionNumber = 1

export default function One() {
  const { user } = useContext(AuthContext)
  const { setCheckpointStep, setError, submitCheckpoint } = useContext(WelcomeContext)
  const [isIncorrect, setIsIncorrect] = useState(false)
  const [answer, setAnswer] = useState('')
  const question = 'Based on your Seekr Report, what milestone did you land in?'

  const { loading, data: report } = useQuery(GET_NEXT_STEPS_DATA, {
    variables: { responseId: user?.responseId },
    onError: (error) => {
      console.error(error)
      setError('Could not find your milestone!')
    },
  })

  const handleChange = (value: any) => {
    setIsIncorrect(false)
    setError('')
    setAnswer(value)
  }

  const submitAnswer = (e: any) => {
    e.preventDefault()

    if (!answer) {
      setError('Please select a milestone below.')
    } else {
      if (report?.reportData?.MilestoneFromMilestoneLink !== answer) {
        setIsIncorrect(true)
      } else {
        setCheckpointStep(2)
      }
      submitCheckpoint({
        variables: {
          missionNumber: missionNumber,
          question: question,
          answer: answer,
          responseId: user?.responseId,
        },
      })
    }
  }
  const renderHint = (answer: any) => {
    return (
      <Box
        borderRadius={'8px'}
        mt={'30px'}
        pt={'17px'}
        pb={'17px'}
        pl={'15px'}
        pr={'15px'}
        backgroundColor={'#343A47'}
        opacity={1}
      >
        <span>
          <Text>Oops, Not Quite There Yet! 🤔</Text>
        </span>
        <span>
          <Text>
            It seems like you're not currently in the {answer} milestone. No worries—let's navigate
            you in the right direction!
          </Text>
        </span>
        <span>
          <Text mt={'15px'} fontWeight={'bold'}>
            What is {answer}?
          </Text>
        </span>
        <span>
          <Text>{milestoneDescriptions[answer as keyof typeof milestoneDescriptions]}</Text>
        </span>
      </Box>
    )
  }
  return (
    <>
      <LogOutButton position={'absolute'} top={5} right={5} />
      <Text
        textTransform={'none'}
        fontFamily={'sans-serif'}
        color="white"
        fontSize={'2xl'}
        fontWeight="700"
        mt={'30px'}
      >
        {question}
      </Text>
      {isIncorrect && renderHint(answer)}
      {loading ? (
        <Spinner />
      ) : (
        <Box mt={'30px'} h={'425px'}>
          <form onSubmit={submitAnswer}>
            <FormControl as="fieldset">
              <RadioGroup onChange={(val) => handleChange(val)}>
                <Stack>
                  <Radio size="lg" value="Clarity" colorScheme="blue">
                    Clarity
                  </Radio>
                  <Radio size="lg" value="Alignment" colorScheme="blue">
                    Alignment
                  </Radio>
                  <Radio size="lg" value="Search Strategy" colorScheme="blue">
                    Search
                  </Radio>
                  <Radio size="lg" value="Interviewing & Advancing" colorScheme="blue">
                    Advance
                  </Radio>
                </Stack>
              </RadioGroup>

              <Button
                mt={'50px'}
                variant="solid"
                w={'111px'}
                bg={'brand.blue'}
                color={'white'}
                title={'Next'}
                type="submit"
                onSubmit={(e) => submitAnswer(e)}
              >
                Next
              </Button>
            </FormControl>
          </form>
        </Box>
      )}
    </>
  )
}
