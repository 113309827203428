import { useQuery } from '@apollo/client'
import {
  Box,
  Text,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SimpleGrid,
  Center,
  Container,
  Stack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { IoIosRocket, IoMdThumbsUp } from 'react-icons/io'
import { CiBookmark } from 'react-icons/ci'
import { Header } from 'src/components'
import { GET_USER_PROFILE_INFO } from 'src/utils/queries'
import JobModal from 'src/components/JobBoard/JobModal'
import JobCard from 'src/components/JobBoard/JobCard'
import { useListJobs } from 'src/services/jobs/hooks'
import ApplicationsTab from 'src/components/JobBoard/ApplicationsTab'
import ToApplyTab from 'src/components/JobBoard/ToApplyTab'

export interface OrganizationProps {
  title: string
  industry: string
  activePartner: boolean
  logo: string
}

export interface LocationProps {
  title: string
}
export interface JobProps {
  title: string
  organizationRef: OrganizationProps
  applicationLink: string
  description: string
  closeDate: string
  datePosted: string
  locationRef: LocationProps
  function: string
  active: string
  jobType: string
  startDate: string
}

export default function JobBoard() {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedJob, setSelectedJob] = useState<JobProps>({
    title: '',
    organizationRef: { title: '', industry: '', activePartner: false, logo: '' },
    applicationLink: '',
    description: '',
    closeDate: '',
    datePosted: '',
    locationRef: { title: '' },
    function: '',
    active: '',
    jobType: '',
    startDate: '',
  })

  const { data } = useQuery(GET_USER_PROFILE_INFO, {
    onError: (error) => {
      console.error(error)
    },
  })

  const { data: allJobsData } = useListJobs()

  const toggleOpenJobModal = (job: JobProps) => {
    setSelectedJob(job)
    setIsOpen(true)
  }

  return (
    <Box height={'100dvh'}>
      <JobModal isOpen={isOpen} job={selectedJob} setIsOpen={setIsOpen} />
      <VStack>
        <Box px={3} w="full">
          <Header firstName={data?.me?.firstName} lastName={data?.me?.lastName} />
          <Container px={{ base: '10px', md: '30px' }} maxW="8xl" py={6}>
            <VStack flexGrow={1} spacing={4} w="full" alignItems="start">
              <Stack direction={{ base: 'column', md: 'row' }} w="full">
                <Box alignItems="start" w="full" justifyContent="space-evenly" my={'70px'}>
                  <Text fontSize={'lg'}>Welcome back!</Text>
                  <Text
                    textTransform={'none'}
                    fontFamily={'sans-serif'}
                    color="white"
                    fontSize={'4xl'}
                    fontWeight="700"
                  >
                    Job & Application Board
                  </Text>
                </Box>
              </Stack>
            </VStack>
          </Container>
        </Box>
      </VStack>

      <Center flexGrow={1} bg="white" py={'30px'}>
        <Tabs
          px={{ base: 0, md: '50px' }}
          variant={'line'}
          size="lg"
          color={'#11192890'}
          w={{ base: '100%', md: '80%' }}
          isLazy
        >
          <TabList>
            <Tab px={'24px'} _selected={{ color: 'black' }}>
              <IoIosRocket /> All Jobs
            </Tab>
            <Tab px={'24px'} _selected={{ color: 'black' }}>
              {' '}
              <CiBookmark /> To Apply
            </Tab>
            <Tab px={'24px'} _selected={{ color: 'black' }}>
              <IoMdThumbsUp /> Applications
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel minH={'649px'}>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacingX={{ md: '25px', lg: '25px' }}
                spacingY={'50px'}
              >
                {allJobsData?.results.map((job: any, i: number) => (
                  <JobCard job={job} key={i} toggleOpenJobModal={toggleOpenJobModal} />
                ))}
              </SimpleGrid>
            </TabPanel>
            <TabPanel minH={'649px'}>
              <ToApplyTab toggleOpenJobModal={toggleOpenJobModal} />
            </TabPanel>

            <TabPanel minH={'649px'}>
              <ApplicationsTab toggleOpenJobModal={toggleOpenJobModal} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Center>
    </Box>
  )
}
