import React, { useContext, useState } from 'react'
import { Text, Box, Button, Textarea, FormControl } from '@chakra-ui/react'
import { AuthContext } from 'src/utils/auth'
import { WelcomeContext } from 'src/utils/providers/useWelcome'
import { useNavigate } from 'react-router-dom'
import LogOutButton from 'src/components/LogOutButton'

const missionNumber = 1

export default function Two() {
  const navigate = useNavigate()
  const { setError, submitCheckpoint } = useContext(WelcomeContext)
  const { user } = useContext(AuthContext)
  const [answer, setAnswer] = useState('')
  const question =
    'Based on your Seekr Survey Results, what is the most important area to focus on now in your job search?'

  const handleChange = (e: any) => {
    let inputValue = e.target.value
    setAnswer(inputValue)
  }

  const submitAnswer = () => {
    if (!answer) {
      setError('Please write an answer in the text box below.')
    } else {
      submitCheckpoint({
        variables: {
          missionNumber: missionNumber,
          question: question,
          answer: answer,
          responseId: user?.responseId,
        },
      })
      navigate('/scheduler')
    }
  }

  return (
    <>
      <LogOutButton position={'absolute'} top={5} right={5} />
      <Text
        textTransform={'none'}
        fontFamily={'sans-serif'}
        color="white"
        fontSize={'2xl'}
        fontWeight="700"
        mt={'30px'}
      >
        {question}
      </Text>

      <Box w={'80%'} mt={'30px'} h={'425px'}>
        <FormControl as="fieldset">
          <Textarea value={answer} onChange={handleChange} placeholder="Type answer..."></Textarea>
          <Button
            mt={'50px'}
            variant="solid"
            w={'111px'}
            bg={'brand.blue'}
            color={'white'}
            title={'Next'}
            onClick={() => submitAnswer()}
          >
            Next
          </Button>
        </FormControl>
      </Box>
    </>
  )
}
