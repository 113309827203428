import { useMutation } from '@apollo/client'
import { Box, Button, Center, Container, Flex, Image, Text, Link } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import basta_logo_white from 'src/assets/images/basta_logo_white.png'
import SeekrSurveyLogo from 'src/components/Survey/SeekrSurveyLogo'
import TextInput from 'src/components/Survey/ui/TextInput'
import { RETRIEVE_SURVEY_POSITION } from 'src/utils/mutations'
import { SurveyContext } from 'src/utils/providers/useSurvey'
import { string } from 'zod'
import LogOutButton from 'src/components/LogOutButton'

const NO_USER_MESSAGE = 'User matching query does not exist.'

const serverToUserErrorDisplay: Record<string, string> = {
  [NO_USER_MESSAGE]: 'There is no user with the given email',
}

export function BeginSurvey() {
  const [error, setError] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const navigate = useNavigate()
  const { setProgress } = useContext(SurveyContext)

  
  //TODO: improve this since it will do two requests, here and in the next route. The ideal thing is that these remain different routes but we lift up the state to a global store which can be accessed by all these.
  const [retrieveCurrentPosition] = useMutation(RETRIEVE_SURVEY_POSITION, {
    variables: {
      email,
    },
    onCompleted: (data: any) => {
      if (
        data?.retrieveCurrentSurveyPosition?.currentQuestion?.options?.length === 0 &&
        data?.retrieveCurrentSurveyPosition?.progress === 1.1 && // to do: change to 1.0 once survey order is permanently changed
        !data?.retrieveCurrentSurveyPosition?.isStaff
      ) {
        setError('You have already submitted a response for this survey.')
        return
      }
      setProgress(data?.retrieveCurrentSurveyPosition?.progress)
      navigate({
        pathname: '/survey/seekr',
        search: createSearchParams({ email: email ?? '' }).toString(),
      })
    },
    onError: (error) => {
      console.error('retrieveCurrentPosition', error.message)
      const parsedError = serverToUserErrorDisplay[error.message] ?? error.message
      setError(parsedError)
    },
  })

  const onBeginSurvey = async () => {
    const parsed = string().email().safeParse(email)
    if (!parsed.success) {
      setError('Invalid email')
      return
    }
    retrieveCurrentPosition()
  }

  return (
    <>
      <Link href="/survey">
        <Box className="border-b-1 border-indigo-600">
          <Image mt={25} ml={5} w="135px" src={basta_logo_white} alt={'Basta logo'} />
        </Box>
      </Link>
      <LogOutButton position={'absolute'} top={0} right={5} />
      <Flex direction={'column'} width={'100vw'} minH={'80vh'} align={'center'} justify={'center'}>
        <SeekrSurveyLogo />
        <Center textAlign={'center'} maxW={'525px'}>
          <Flex
            as="form"
            direction={'column'}
            justify="center"
            align="center"
            onSubmit={(e) => {
              e.preventDefault()
              onBeginSurvey()
            }}
          >
            <Container>
              <Text color={'#FFFFFF'}>
                Enter the email associated with your account to continue
              </Text>
            </Container>

            <Box py={2} w="full">
              <TextInput
                value={email ?? ''}
                onChange={(e) => {
                  setError(null)
                  setEmail(e.target.value)
                }}
                placeholder="Enter email address..."
              />
            </Box>
            {error && (
              <Text alignSelf={'flex-start'} mt={'10px'} color="red">
                {error}
              </Text>
            )}

            <Button
              w={'500px'}
              variant="solid"
              bg={'brand.blue'}
              color={'white'}
              title={'Begin Seekr Survey'}
              mt={'10px'}
              onClick={onBeginSurvey}
              isDisabled={!email || Boolean(error)}
            >
              Begin Seekr Survey
            </Button>
          </Flex>
        </Center>
      </Flex>
    </>
  )
}
