import { useQuery } from '@tanstack/react-query'
import { jobApi } from './api'

export const useListJobs = () => {
  const { data } = useQuery({
    queryKey: ['jobs'],
    queryFn: async () => {
      return await jobApi.list()
    },
    enabled: true,
  })
  return { data }
}
