import { Button, Icon, SimpleGrid, VStack, Text } from '@chakra-ui/react'
import React from 'react'
import JobCard from './JobCard'
import { useListJobActivities } from 'src/services/jobActivity/hooks'
import { PiFloppyDiskBack } from 'react-icons/pi'

interface ToApplyTabProps {
  toggleOpenJobModal: any
}

export default function ToApplyTab({ toggleOpenJobModal }: ToApplyTabProps) {
  const { data: toApplyData } = useListJobActivities({
    dateApplied__isnull: true,
    interested: true,
    job__active: true,
  })

  const ToApplyEmptyTab = () => {
    return (
      <VStack h={'100%'} justifyContent={'center'}>
        <Icon as={PiFloppyDiskBack} boxSize={16} />
        <Text
          textTransform={'none'}
          fontFamily={'sans-serif'}
          color="black"
          fontSize={'xl'}
          fontWeight="500"
        >
          You have not saved any jobs yet
        </Text>
        <Text>Take a look at Basta's recommendations to start saving jobs!</Text>
        <Button
          variant="solid"
          _hover={{ bg: '#3764b4' }}
          bg={'brand.blue'}
          color={'white'}
          title={'RSVP'}
          fontWeight={500}
          width={'231px'}
          h={'56px'}
          borderRadius={'100px'}
          mt={'20px'}
        >
          View Recommended Jobs
        </Button>
      </VStack>
    )
  }
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3 }}
      spacingX={{ md: '25px', lg: '25px' }}
      spacingY={'50px'}
    >
      {toApplyData?.results.length === 0 ? (
        <ToApplyEmptyTab />
      ) : (
        toApplyData?.results.map((jobActivity: any, i: number) => (
          <JobCard job={jobActivity?.jobRef} key={i} toggleOpenJobModal={toggleOpenJobModal} />
        ))
      )}
    </SimpleGrid>
  )
}
