import React, { Dispatch, SetStateAction } from 'react'
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Divider,
  Box,
  Image,
  Text,
  Container,
  Center,
  ModalFooter,
  Button,
  Link,
  ModalCloseButton,
} from '@chakra-ui/react'
import { JobProps } from 'src/pages/JobBoard'

interface JobModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  job: JobProps
}

export default function JobModal({ setIsOpen, isOpen, job }: JobModalProps) {
  const toggleCloseModal = () => {
    setIsOpen(false)
  }
  return (
    <Modal isOpen={isOpen} onClose={toggleCloseModal} isCentered size={'lg'}>
      {' '}
      <ModalOverlay bg={'#0000001A'} />
      <ModalContent
        p={'50px'}
        backgroundColor={'white'}
        w={'1000px'}
        borderRadius={'20px'}
        shadow={'md'}
      >
        <Center>
          <Image
            src={job?.organizationRef?.logo}
            borderRadius={'36px'}
            border={'1px solid #1119281A'}
            mr={2}
            width={'44px'}
            height={'44px'}
            objectFit={'cover'}
            p={'5px'}
          />
        </Center>

        <ModalHeader m={'auto'} color={'black'}>
          {job?.title}
        </ModalHeader>
        <ModalCloseButton color="black" />
        <Divider
          height={'3px'}
          w={'90px'}
          m={'auto'}
          mb={'16px'}
          borderRadius={'2px'}
          opacity={100}
          orientation="horizontal"
          backgroundColor={'brand.darkBlue'}
        />
        <Center my={'20px'}>
          <Text color={'#637381'} fontSize={'lg'}>
            {job?.organizationRef?.title} {'\u25CF'} {job?.locationRef?.title}
          </Text>
        </Center>
        <ModalBody px={0} textAlign={'center'}>
          <Box>
            <Text textColor="#111928B2" fontFamily={'Inter'} fontSize={14} fontWeight={'500'}>
              {job?.description}
            </Text>
          </Box>

          <Container
            overflowY={'scroll'}
            maxH={'250px'}
            sx={{
              '&::-webkit-scrollbar': {
                width: '8px',
                borderRadius: '4px',
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
            }}
          ></Container>
        </ModalBody>
        <ModalFooter justifyContent={'space-between'}>
          {/* <Button
            border={'1px solid #DFE4EA'}
            borderRadius={'100px'}
            variant="solid"
            _hover={{ bg: 'brand.white' }}
            bg={'white'}
            color={'black'}
            title={'Add to My List'}
            fontWeight={500}
            p={'13px 28px 13px 28px'}
          >
            Add to My List
          </Button> */}
          <Link href={job?.applicationLink} isExternal>
            <Button
              variant="solid"
              _hover={{ bg: '#3764b4' }}
              bg={'brand.blue'}
              color={'white'}
              title={'RSVP'}
              fontWeight={500}
              borderRadius={'100px'}
              p={'13px 28px 13px 28px'}
            >
              Job Link
            </Button>
          </Link>
        </ModalFooter>
      </ModalContent>{' '}
    </Modal>
  )
}
