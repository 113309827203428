import { createApi } from '@thinknimble/tn-models'
import { axiosInstance } from '../axios-instance'
import { JobCreateShape, JobShape } from './models'

export const jobApi = createApi({
  client: axiosInstance,
  baseUri: 'employment/jobs/',
  models: {
    entity: JobShape,
    create: JobCreateShape,
  },
})
